
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Multiselect from '@vueform/multiselect';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { ElNotification } from 'element-plus';
import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'student-summary-dynamicreport',
  components: {
    Datatable,
    Multiselect,
  },

  data() {
    return {
      pdfHeader: [] as any,
      apiUrl: '',
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        poor: '',
        challenged: '',
        ethnic: '',
        gender: '',
      },
      selected: {},
      entityInfos: [] as any,
        TpartnerData: [] as any,
      TranceData: [] as any,
      instituteList: [] as any,
      tranches_info: [] as any,
      courseList: [] as any,
      tableData: [] as any,
      tableHeader: [
        {
          name: 'SL',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: false,
          selected: true,
        },
        {
          name: 'Entity',
          key: 'entity',
          sortable: true,
          selected: false,
        },
        {
          name: 'Institute Name',
          key: 'institute_name',
          sortable: true,
          selected: false,
        },
        {
          name: 'Registration Number',
          key: 'reg_number',
          sortable: true,
          selected: true,
        },
        {
          name: 'Trainee Name',
          key: 'name',
          sortable: true,
        },
        {
          name: 'NID',
          key: 'nid',
          sortable: true,
          selected: true,
        },
        {
          name: 'BCN',
          key: 'bcn',
          sortable: true,
          selected: true,
        },
        {
          name: 'Present Address',
          key: 'present_add',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Present City',
          key: 'present_city',
          sortable: true,
        },
        {
          name: 'Present Post Code',
          key: 'postCode',
          sortable: true,
        },
        {
          name: 'Present District',
          key: 'present_district',
          sortable: true,
        },
        {
          name: 'mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          selected: true,
          sortable: true,
        },
        {
          name: 'Batch Number',
          key: 'batch_number',
          selected: true,
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Active Status',
          key: 'active_status',
          sortable: true,
        },
      ] as any,

      poorSearchoption: [
        {
          name: 'Yes',
          value: '1',
        },
        {
          name: 'No',
          value: '0',
        },
      ],
      ethnicoption: [
        {
          name: 'Yes',
          value: 'yes',
        },
        {
          name: 'No',
          value: 'no',
        },
      ],
      challengedoption: [
        {
          name: 'Yes',
          value: 'yes',
        },
        {
          name: 'No',
          value: 'no',
        },
      ],
      genderoption: [
        {
          name: 'Male',
          value: 'male',
        },
        {
          name: 'Female',
          value: 'female',
        },
        {
          name: 'Others',
          value: 'other',
        },
      ],
      TpartnerLabel: '',
       optionsTPartner: [] as any,
      optionsTranche: [] as any,
      TpartnerInfos: [] as any,
      TrancheInfos:[] as any,
      TranceLabel: '',
      TinstituteLabel: '',
      TcourseLabel: '',
      PoorLabel: '',
      EthnicLabel: '',
      PhysicalLabel: '',
      GenderLabel: '',
      st: [] as any,
      loading: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
    };
  },
  async created() {
    this.apiUrl = this.VUE_APP_API_URL;
   await this.getAssociation();
    await this.getTranche();
  },
   watch: {
    "TpartnerData": {
      handler: "getTrainingInstitute", // Call the method when batch.entity_id changes
      immediate: false, // Set this to false if you want to trigger on initial mount
    },
  },
  methods: {
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('tranche_id', this.TranceData);
      formData.set('entity_id', this.TpartnerData);
      formData.set('institute_id', this.batch.institute_id);
      formData.set('course_id', this.batch.course_id);
      formData.set('poor', this.batch.poor);
      formData.set('challenged', this.batch.challenged);
      formData.set('gender', this.batch.gender);
      formData.set('ethnic', this.batch.ethnic);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/studentreportExcel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'StudentReport.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('tranche_id', this.TranceData);
      formData.set('entity_id', this.TpartnerData);
      formData.set('institute_id', this.batch.institute_id);
      formData.set('course_id', this.batch.course_id);
      formData.set('poor', this.batch.poor);
      formData.set('challenged', this.batch.challenged);
      formData.set('gender', this.batch.gender);
      formData.set('ethnic', this.batch.ethnic);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('report/studentreportPdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async printPdf() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('tranche_id', this.TranceData);
      formData.set('entity_id', this.TpartnerData);
      formData.set('institute_id', this.batch.institute_id);
      formData.set('course_id', this.batch.course_id);
      formData.set('poor', this.batch.poor);
      formData.set('challenged', this.batch.challenged);
      formData.set('gender', this.batch.gender);
      formData.set('ethnic', this.batch.ethnic);

      let data = `${this.VUE_APP_API_URL}/api/report/studentreportprint`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    trancheLabelChange(id) {
      let selectedtranche = this.tranches_info.filter((data) => data.id == id);

      this.TranceLabel = selectedtranche[0].label;
    },
    courseLabelChange(id) {
      let selectedcourse = this.courseList.filter((data) => data.id == id);

      this.TcourseLabel = selectedcourse[0].course_name;
    },
    poorLabelChange(id) {
      let selectedPoor = this.poorSearchoption.filter(
        (data) => data.value == id
      );

      this.PoorLabel = selectedPoor[0].name;
    },
    enthicLabelChange(id) {
      let selectedEtchic = this.ethnicoption.filter((data) => data.value == id);

      this.EthnicLabel = selectedEtchic[0].name;
    },
    physicalLabelChange(id) {
      let selectedPhysical = this.challengedoption.filter(
        (data) => data.value == id
      );

      this.PhysicalLabel = selectedPhysical[0].name;
    },
    genderLabelChange(id) {
      let selectedGender = this.genderoption.filter((data) => data.value == id);

      this.GenderLabel = selectedGender[0].name;
    },
     async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
   async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
        this.TpartnerData +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async courseInfo(id) {
      this.loading = true;
      let selectedInstitute = this.instituteList.filter(
        (data) => data.id == id
      );
      this.TinstituteLabel = selectedInstitute[0].short_name;
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
        '?entity_id=' +
        this.TpartnerData +
        '&tranche=' +
        this.TranceData +
        '&institute_info_id=' +
        this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    resetData() {
      this.tableData = [] as any;
      this.batch = {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        poor: '',
        challenged: '',
        ethnic: '',
        gender: '',
      };
      this.TpartnerLabel = '';
      this.TranceLabel = '';
      this.TinstituteLabel = '';
      this.TcourseLabel = '';
      this.PoorLabel = '';
      this.EthnicLabel = '';
      this.PhysicalLabel = '';
      this.GenderLabel = '';
      this.headerSelect = false;
      this.showall = false;
      this.showTableData = false;
      this.componentTableKey += 1;
    },

    async generateInstituteReport() {
      this.loading = true;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        this.TpartnerData = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        this.batch.institute_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'trainee/report?tranche_id=' +
        this.TranceData +
        '&entity_id=' +
        this.TpartnerData +
        '&institute_id=' +
        this.batch.institute_id +
        '&course_id=' +
        this.batch.course_id +
        '&poor=' +
        this.batch.poor +
        '&challenged=' +
        this.batch.challenged +
        '&gender=' +
        this.batch.gender +
        '&ethnic=' +
        this.batch.ethnic
      )
        .then((response) => {
          this.headerSelect = true;
          this.selectedTableHeader();
          this.tableData = response.data.data;
          this.componentTableKey += 1;
          this.loading = false;
        })
        .then((response) => {
          console.log(response);
          this.loading = false;
        });
    },

    selectedTableHeader() {
      this.st = Array();
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
    },
  },

  computed: {},
  setup() { },
});
